import React, { useEffect } from 'react';

const ClimateNewsletter: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";    
    document.body.appendChild(script);  
  }, []);
  return (
    <div className="climate-newsletter">
      <div id="mc_embed_signup">
        <form
          action="https://oaktyres.us13.list-manage.com/subscribe/post?u=2091d60a42711f72792766d50&amp;id=ac05749ef1"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <h2>Sign up to stay up to date with our climate positive movement</h2>            
            <div className="mc-field-group">              
              <input type="text" defaultValue="" placeholder="Name" name="NAME" className="required" id="mce-NAME" />
            </div>
            <div className="mc-field-group">              
              <input type="email" defaultValue="" placeholder="Email" name="EMAIL" className="required email" id="mce-EMAIL" />
            </div>            
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input type="text" name="b_2091d60a42711f72792766d50_ac05749ef1" defaultValue="" />
            </div>
            <div className="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"                
              />
            </div>
            <div id="mce-responses" className="clear">              
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
          </div>
        </form>
      </div>            
    </div>
  );
};

export default ClimateNewsletter;
