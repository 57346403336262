import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import Layout from '@components/Layout';
import Images from '@components/Images';
import EcologiStats from '@components/EcologiStats';
import ClimateNewsletter from '@components/ClimateNewsletter';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { extractGraphqlData } from '@utils/extractors';

interface IEcologiProps {
  data: {
    prismic: {
      allEcologi_pages: {
        edges: {
          node: any;
        }[];
      };
    };
  };
}

const Ecologi: React.FC<IEcologiProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allEcologi_pages');
  if (!doc) return null;

  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];

  return (
    <Layout
      title={page_title ? RichText.asText(page_title) : 'Ecologi'}
      description={page_description && RichText.asText(page_description)}
      firstLevelPage
      seo={{
        path: '/ecologi',
        canonical: canonical_tag && canonical_tag.url,
        image: page_og_image ? page_og_image : null,
      }}
    >
      <div className="ecologi">
        {/* main block */}
        <div className="ecologi__content">
          <div className="ecologi__text">
            <div className="anim-title">
              <h1>
                {RichText.asText(doc.node.heading)}
                <br />
                {RichText.asText(doc.node.heading_below)}
              </h1>
            </div>
            <div className="anim-block">{RichText.render(doc.node.body_text, linkResolver)}</div>
          </div>
          <div className="ecologi__panoramic">
            <Images
              imageData={[{ image: doc.node.body_image }]}
              extractPath="image"
              alt={_get(doc, ['node', 'body_image', 'alt'])}
              classes={{
                base: 'anim-bottom-image',
                extend: 'ecologi',
                wrap: 'ecologi_panoramic-img',
                class: '',
              }}
              width="1314"
              setHeight={false}
            />
          </div>
          <div className="ecologi__text secondary">
            <div className="anim-next-block">{RichText.render(doc.node.body_bottom, linkResolver)}</div>
          </div>
        </div>

        {/* sidebar */}
        <div className="ecologi__secondary">
          <div className="ecologi__logo">             
              <div className="logo"></div>
          </div>
          <div className="ecologi__stats">
            <EcologiStats goalData={{ carbon: doc.node.goal_carbon, trees: doc.node.goal_trees }} />
          </div>
          <div className="ecologi__links">
            <a
              href="https://ecologi.com/oaktyres/"
              target="_blank"
              className="our-brands__link ecologi__center"
            >
              <span className="our-brands__link-arrow"></span>
              ecologi.com/oaktyres
            </a>
          </div>
          <div className="ecologi__subscribe anim-next-block">
            <ClimateNewsletter />
          </div>
        </div>
      </div>
      {/* mountains */}
      <div className="ecologi__mountains anim-next-block">          
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Ecologi {
    prismic {
      allEcologi_pages {
        edges {
          node {
            heading
            heading_below
            body_text
            body_image
            body_bottom
            goal_carbon
            goal_trees
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Ecologi;
