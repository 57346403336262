import React, { useEffect, useState } from 'react';
import SingleStat from '../SingleStat';
import axios from 'axios';

interface IStatWrapperProps {
  property: string;
  text: string;
  roundToK: boolean;
  goalValue: number;   
  start: boolean;  
  color?: string;
  done?: () => any;
};

const timeOut = 1500;

const getDisplayValue = (value: number, roundToK: boolean):string => {
  return roundToK ? `${Math.round(value / 1000)}k` : `${value}`;
}

const StatWrapper: React.FC<IStatWrapperProps> = ({ property, text, goalValue, roundToK, start, color, done }) => {
  // load data
  const [value, setValue] = useState('');
  const [percents, setPercents] = useState(0);  
  const [phase, setPhase] = useState(0);  // 0 - entry, 1 - start draw, 2 - finished

  useEffect(() => {
    const loadData = async () => {
        const response = await axios.get(`https://public.ecologi.com/users/oaktyres/${property}`);
        // After fetching data stored it in posts state.                
        if(response.data && response.data.total) {
          setValue(getDisplayValue(response.data.total, roundToK));
          const finalPercent = (response.data.total / goalValue) * 100;
          setPercents(finalPercent);
        }                
    }
    // Call the function
    if(phase === 0 && start) {
      setValue('');
      setPercents(0);
      loadData();    
      setPhase(1);
    }
    if(phase === 1) {
      setTimeout(() => setPhase(2),timeOut);
    }
    if(phase === 2) {
      if(typeof done === "function") {
        done();
      }
    }
  }, [phase, start]);
  return (    
    <SingleStat value={value} percentage={percents} text={text} color={color} />            
  );
};

export default StatWrapper;
