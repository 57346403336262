import React, { useEffect, useState } from 'react';
import StatWrapper from './StatWrapper';
import TextWrapper from './TextWrapper';

interface IEcologiStatsProps {
  goalData: any;
}

const EcologiStats: React.FC<IEcologiStatsProps> = ({ goalData }) => {
  const [phase, setPhase] = useState(0);
  return (
    <div className="ecologi-stats">
      <StatWrapper
        property="trees"
        text="trees planted"
        goalValue={goalData.trees}
        roundToK
        color="#68BB87"
        done={() => setPhase(1)}
        start={true}
      />
      <TextWrapper
        text="&amp;"
        start={phase === 1}
        done={() => setPhase(2)}
      />
      <StatWrapper
        property="carbon-offset"
        goalValue={goalData.carbon}
        text="tonnes of carbon reduction"
        roundToK
        color="#EAB84B"
        start={phase === 2}
      />
    </div>
  );
};

export default EcologiStats;
