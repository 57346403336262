import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface ISingleStatProps {  
  text: string;
  percentage: number;
  value: string;  
  color?: string; 
}

const SingleStat: React.FC<ISingleStatProps> = ({ text, percentage, value, color }) => {  
  const displayText = percentage === 0 ? '&nbsp;' : text;
  return (
    <div className="single-stat">
			<div className="stat-circle">
				<CircularProgressbar 
					value={percentage} 
					text={value} 
					strokeWidth={20}
					styles={buildStyles({
						strokeLinecap: 'butt',
						pathColor: color ? color : "#000",
						textColor: '#000',
						pathTransitionDuration: 1.5
					})}
				/>
			</div>
      <div className={`stat-title ${percentage > 0 ? 'active': ''}`}>{displayText}</div>
    </div>
  );
};

export default SingleStat;
