import React, { useEffect, useState } from 'react';

interface ITextWrapperProps {
  text: string;
  start: boolean;
  done?: () => any;
}

const timeOut = 700;

const TextWrapper: React.FC<ITextWrapperProps> = ({ text, start, done }) => {
	const [phase, setPhase] = useState(0);  // 0 - entry, 1 - start draw, 2 - finished
	useEffect(() => {
		if(start) {
			setPhase(1);
			setTimeout(() => setPhase(2), timeOut);
		}
		if(phase === 2) {
			if(typeof done === "function") {
        done();
      }
		}
	}, [phase, start]);
	return (
		<div className={`stat-text ${phase > 0 ? 'active': ''}`}>
			{phase > 0 ? text : '...'}
		</div>		
	);
}

export default TextWrapper;